import React from 'react'
import Img from 'gatsby-image'
import {
  FiMail,
  FiPhone,
  FiClock,
  FiRadio,
  FiPrinter,
  FiLifeBuoy,
  FiEdit3,
} from 'react-icons/fi'

import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import Row from 'src/components/row'
import { Helmet } from 'react-helmet'
import Form from 'src/components/form'

const Page = ({ data }) => (
  <Layout>
    <div>
      <Helmet>
        <title>Contact Us - {data.site.siteMetadata.title}</title>
      </Helmet>
      <div className="content">
        <Row columns={2}>
          <div>
            <h2 id="email">
              <FiEdit3 /> Send us an Email{' '}
            </h2>
            <p>Fill out this form and we'll get back to you shortly!</p>
            <Form />
          </div>
          <div>
            <h2>ARC General Contracting</h2>
            <p>
              11 Jutland Road
              <br />
              Toronto, Ontario, M8Z 2G6
              <br />
              <FiPhone /> <a href="tel:(416)203-7441">(416) 203 7441</a>
              <br />
              <FiPrinter /> (416) 255 5690
              <br />
              <FiMail /> <a href="mailto:arccon@arccon.ca">arccon@arccon.ca</a>
            </p>

            <h3>
              <FiLifeBuoy /> 24 Hour Emergency Number:
            </h3>
            <p>
              <FiRadio /> <a href="tel:(647)479-2145">(647) 479 2145</a>
            </p>

            <h3>
              <FiClock /> Hours of Operation:
            </h3>
            <p>Monday - Friday, 08:00 a.m. - 04:00 p.m.</p>

            <Img fixed={data.imgMap.childImageSharp.fixed} />
          </div>
        </Row>
      </div>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query ContactUsQuery {
    site {
      siteMetadata {
        title
      }
    }
    imgMap: file(relativePath: { regex: "/map2.png/" }) {
      childImageSharp {
        fixed(width: 285) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
